<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-program"
      v-model="showModal"
      title="Add New Template"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="name"
              :rules="{ required: true }"
              vid="name"
              name="Template Name"
            >
              <b-form-group
                label="Template Name"
                label-for="program-name"
              >
                <b-form-input
                  id="program-name-input"
                  v-model="localProgram.name"
                  placeholder="Template Name"
                  maxlength="255"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="programType"
              rules="required"
              name="Program Type"
            >
              <b-form-group
                label="Program Type"
                label-for="program-type"
              >
                <v-select
                  id="program-type"
                  v-model="localProgram.type_id"
                  label="text"
                  :reduce="filteredProgramTypeDisplay => filteredProgramTypeDisplay.id"
                  placeholder="Select the Program Type"
                  :options="filteredProgramTypeDisplay"
                  :state="getValidationState(validationContext)"
                  :disabled="localProgram.id != null"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          @click="ok()"
        >
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span v-if="isUpdating">Updating...</span>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BFormInvalidFeedback,
  VBModal,
  BButton,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getValidationState, makeErrorToast, makeSuccessToast } from "@/libs/utils";
import programsService from "@/services/programsService";
import { ProgramClass } from '@models/classes/programClass';
import { programTypeDisplay, programTypes } from "@/models";
import _isEqual from 'lodash/isEqual';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    vSelect,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    program: {
      type: Object,
      default: () => new ProgramClass(),
    },
  },
  data() {
    return {
      localProgram: { ...this.program },
      name: null,
      dir: "ltr",
      clientsList: [],
      showModal: false,
      isUpdating: false
    };
  },
  computed: {
    filteredProgramTypeDisplay() {
      return this.programTypeDisplay.filter(i => i.id !== programTypes.TRAINING);
    }
  },
  watch: {
    program: {
      handler(newProgram, oldProgram) {
        if (_isEqual(newProgram, oldProgram)) {
          return;
        }
        if (newProgram) {
          this.localProgram = { ...newProgram };
        } else {
          this.localProgram = { type_id: programTypes.STANDARD };
        }
      },
    },
  },
  methods: {
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.localProgram = new ProgramClass();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        this.localProgram.id
          ? await programsService.updateTemplate(this.localProgram.id, this.localProgram)
          : await programsService.createTemplate(this.localProgram);

        this.showModal = false;
        this.$emit("programAdded");
        this.$toast(makeSuccessToast("Program saved."));
        await this.$store.dispatch('programs/GET_PROGRAMS');
      } catch (e) {
        const { status, data } = e.response;
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast("Something went wrong. Program not saved."));
          this.$log.error(e);
        }
      } finally {
        this.isUpdating = false;
      }
    },
  },
  setup() {
    return {
      getValidationState,
      programTypeDisplay
    };
  },
};
</script>
